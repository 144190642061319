import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'

class Services extends Component {
    render() {
        return (
            <div id='services' className='grid grid-cols-12'>
                <div className='col-start-2 md:col-start-2 xl:col-start-3 grid grid-cols-4 gap-5 col-span-10 xl:col-span-8'>
                    {/* 1 */}
                    <div
                        data-aos="fade-up"
                        className='flex flex-col-reverse xl:grid xl:grid-cols-3 gap-y-5 xl:gap-y-0 col-span-4 md:col-span-2 bg-blue-500 rounded-xl px-8 py-10'
                    >
                        <div className='col-span-2 pr-5 flex flex-col gap-y-4'>
                            <div className='flex flex-col gap-y-2.5'>
                                <h6 className='text-white text-3xl font-medium'>Групповые занятия</h6>
                                <p className='text-white text-base opacity-90 leading-6'>Изучение английского языка в группе — отличный способ привыкнуть к акцентам и нюансам произношения. </p>
                            </div>

                            <div className='hidden xl:block'>
                                <button className='bg-white hover:bg-blue-500 border hover:border-white rounded-md text-blue-500 hover:text-white py-2 px-3 text-sm transition duration-300 ease-in-out'>
                                    <AnchorLink offset='180' href='#contact'>Попробовать</AnchorLink>
                                </button>
                            </div>
                        </div>

                        <img alt='' className='h-56 xl:h-auto' src="./img/plan.svg" />
                    </div>

                    {/* 2 */}
                    <div 
                        data-aos="fade-up"
                        className='col-span-4 md:col-span-2 xl:row-span-2 flex flex-col-reverse xl:flex-col gap-y-4 bg-white rounded-xl px-8 py-10'
                    >
                        <div className='flex flex-col gap-y-4'>
                            <div className='flex flex-col gap-y-2.5'>
                                <h6 className='text-gray-800 text-3xl font-medium'>Online занятия</h6>
                                <p className='text-gray-800 text-base opacity-80 leading-6'>Самый популярный способ учить английский онлайн!<br />Доступное дистанционное обучение английскому языку через интернет. Уроки могут проходить в Skype или Zoom.</p>
                            </div>

                            <div className='hidden xl:block'>
                                <button className='bg-blue-500 hover:bg-white border hover:border-blue-500 rounded-md text-white hover:text-blue-500 py-2 px-3 text-sm transition duration-300 ease-in-out'>
                                    <AnchorLink offset='180' href='#contact'>Записаться</AnchorLink>
                                </button>
                            </div>
                        </div>

                        <div className='flex flex-row justify-start md:justify-center'>
                            <img alt='' className='h-56 xl:h-auto xl:w-3/5' src="./img/blogging.svg" />
                        </div>
                    </div>

                    {/* 3 */}
                    <div 
                        data-aos="fade-up"
                        className='col-span-4 md:col-span-2 xl:col-span-1 flex flex-col gap-y-2 bg-white rounded-xl pl-8 pr-7 py-6'
                    >
                        <div>
                            <img alt='' className='h-56 xl:h-auto' src="./img/meditation.svg" />
                        </div>

                        <div className='flex flex-col gap-y-1.5'>
                            <h6 className='text-gray-800 text-3xl xl:text-lg font-medium'>Индивидуальные</h6>
                            <p className='text-base text-gray-800 opacity-80 leading-5'>Персональные занятия по направлению общий Английский язык.</p>
                        </div>
                    </div>

                    {/* 4*/}
                    <div
                        data-aos="fade-up"
                        className='col-span-4 md:col-span-2 xl:col-span-1 flex flex-col gap-y-2 bg-white rounded-xl pl-8 pr-7 py-6'
                    >
                        <div>
                            <img alt='' className='h-56 xl:h-auto' src="./img/growth.svg" />
                        </div>

                        <div className='flex flex-col gap-y-1.5'>
                            <h6 className='text-gray-800 text-3xl xl:text-lg font-medium'>Подготовка к экзаменам</h6>
                            <p className='text-base text-gray-800 opacity-80 leading-5'>Персональные занятия по направлению общий Английский язык.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Services;