import React, { Component } from "react";

class Price extends Component {
  render() {
    return (
      <div id="price" className="flex flex-col gap-y-24 xl:gap-y-32">
        <div data-aos="fade-up" className="flex flex-row justify-center">
          <h2 className="text-5xl font-extrabold text-gray-800">Прайс лист</h2>
        </div>

        <div
          data-aos="fade-up"
          className="flex flex-col justify-center md:flex-row gap-10 xl:gap-x-12"
        >
          <div className="md:w-1/2 xl:w-1/4 flex flex-col gap-y-2 border border-gray-300 px-8 py-10 rounded-xl">
            <h3 className="text-2xl xl:text-3xl uppercase font-bold text-gray-800 text-center whitespace-pre-line">
              ГРУППОВЫЕ
              <br />
              ЗАНЯТИЯ
            </h3>

            <div className="flex flex-row items-center justify-center">
              <p className="text-2xl font-bold text-blue-500">600 ₽</p>
              <p className="text-sm text-gray-800 opacity-80">/ урок</p>
            </div>

            <div className="flex flex-col justify-center gap-y-3.5 xl:gap-y-5 mt-5 xl:mx-5">
              {[
                "Проведение собеседования на выявление уровня",
                "Разработка индивидуального плана работы",
                "Методика обучения по Oxford и Cambridge",
                "Личностный подход",
                "Занятия с высококвалифицированным преподавателем",
                "Помощь в выполнении домашних заданий",
                "Возможно обучение по Skype",
              ].map((text, index) => (
                <div key={index} className="flex flex-row gap-x-3 items-center">
                  <img alt="" src="./img/check-circle.png" />
                  <p className="text-base text-gray-800 opacity-90">{text}</p>
                </div>
              ))}
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="md:w-1/2 xl:w-1/4 flex flex-col gap-y-2 border border-gray-300 px-8 py-10 rounded-xl"
          >
            <h3 className="text-2xl xl:text-3xl uppercase font-bold text-gray-800 text-center whitespace-pre-line">
              ИНДИВИДУАЛЬНЫЕ
              <br />
              ЗАНЯТИЯ
            </h3>

            <div className="flex flex-row items-center justify-center">
              <p className="text-2xl font-bold text-blue-500">1100 ₽</p>
              <p className="text-sm text-gray-800 opacity-80">/ урок</p>
            </div>

            <div className="flex flex-col justify-center gap-y-3.5 xl:gap-y-5 mt-5 xl:mx-5">
              {[
                "Проведение собеседования на выявление уровня",
                "Разработка индивидуального плана работы",
                "Методика обучения по Oxford и Cambridge",
                "Личностный подход",
                "Персональные занятия с высококвалифицированным преподавателем",
                "Помощь в выполнении домашних заданий",
                "Возможно обучение по Skype",
              ].map((text, index) => (
                <div key={index} className="flex flex-row gap-x-3 items-center">
                  <img alt="" src="./img/check-circle.png" />
                  <p className="text-base text-gray-800 opacity-90">{text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Price;
