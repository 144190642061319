import React, { Component } from 'react';
import { Carousel } from "react-responsive-carousel";

class Reviews extends Component {
    render() {
        return (
            <div id='reviews' className='flex flex-col gap-y-24 xl:gap-y-32'>
                <div data-aos="fade-up" className='flex flex-row justify-center'>
                    <h2 className='text-5xl font-extrabold text-gray-800'>Отзывы</h2>
                </div>


                <div data-aos="fade-up" className='grid grid-cols-12'>
                    <div className='xl:col-start-3 xl:col-end-11 col-span-12 xl:col-span-10'>
                        <Carousel
                            showIndicators={false}
                            showArrows={true}
                            showThumbs={false}
                            showStatus={false}      
                            emulateTouch={false} 
                            preventMovementUntilSwipeScrollTolerance           
                            infiniteLoop
                            stopOnHover                           
                        >

                            <div className='flex flex-col gap-y-8 md:gap-y-12 xl:gap-y-14'>
                                <p className='text-xl md:text-2xl text-gray-800'>
                                    “До занятий в этой школе - я практически не могла ничего сказать, построить предложения на английском..но!! я
                                    уже занимаюсь больше трех лет в этой школе, с замечательным предподователем Олесей Дмитриевной и могу сказать,
                                    что за все занятия я узнала столько нового, интересного, чего не изучаем мы в школе! Олеся Дмитриевна
                                    прекрасно объясняет материал, не с помощью зубрежки, а с помощью примеров. делится с ребятами своим личным
                                    опытом и примерами из своей жизни. также она находит общий язык с нами, шутит, поддерживает. в общем,
                                    замечательная школа и такой же учитель!!!”
                                </p>
                                <div className='flex flex-col items-center gap-y-2'>
                                    <p className='text-base md:text-lg font-medium'>Милана Жанжигитова</p>
                                    <div className='relative w-16 h-16'>
                                        <img alt='' src="./img/ot1 1.png" />
                                    </div>
                                </div>
                            </div>


                            <div className='flex flex-col gap-y-8 md:gap-y-12 xl:gap-y-14 px-5 md:px-12 xl:px-16'>
                                <p className='text-xl md:text-2xl text-gray-800'>
                                    “Я живу в Москве и занимаюсь в школе английского языка Олеси Дмитриевны Турбабиной онлайн уже 10 месяцев. Очень
                                    нравится структура занятий, каждый урок очень информативный, нет воды, все четко и понятно. Отдельно хочется
                                    отметить подход к каждому студенту, перед приёмом в школу, Олеся Дмитриевна лично проводит собеседование. На
                                    собеседовании выявляются потребности и уровень языка, это делается для того, чтобы подобрать студенту своего
                                    учителя. Я занимаюсь с Ходос Алиной Сергеевной, это замечательный человек, который умеет доносить информацию
                                    так, что поймёт каждый, если я не понимаю тему, мы дожимаем ее до победного, пробуем разные подходы, Алина
                                    Сергеевна всегда на связи.
                                    Английский язык больше не кажется чём-то сложным и непонятным, как было в школе.
                                    Аудирования подобраны идеально, с британским и американским акцентом, что помогает понимать язык ещё лучше.
                                    Спасибо за вашу работу и наши совместные успехи 🙏🏼❤️”
                                </p>
                                <div className='flex flex-col items-center gap-y-2 px-5 md:px-12 xl:px-16'>
                                    <p className='text-base md:text-lg font-medium'>Анна Любшина</p>
                                    <div className='relative w-16 h-16'>
                                        <img alt='' src="./img/ot2.png" />
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-col gap-y-8 md:gap-y-12 xl:gap-y-14 px-5 md:px-12 xl:px-16'>
                                <p className='text-xl md:text-2xl text-gray-800'>
                                    “Мне очень нравится уроки с Алиной. Ведь на них даже если ты ошибся, тебя не отругают а поправят и скажут чтоб ты подумал ещё раз.На уроках Алины весело потому что:мы не просто в сухую изучаем тему а ещё играем в игры где закрепляется материал.”
                                </p>
                                <div className='flex flex-col items-center gap-y-2'>
                                    <p className='text-base md:text-lg font-medium'>Рочегов Фёдор</p>
                                    <div className='relative w-16 h-16'>
                                        <img alt='' src="./img/fedor.png" />
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-col gap-y-8 md:gap-y-12 xl:gap-y-14 px-5 md:px-12 xl:px-16'>
                                <p className='text-xl md:text-2xl text-gray-800'>
                                    “Очень благодарна Школе и лично Олесе за помощь в подготовке к экзаменам престижной международной школы вина
                                    и крепкого алкоголя WSET(Лондон) и к сертификации Международной Ассоциации Сомелье(ASI). Для меня было важным,
                                    что я училась думать и понимать язык, а не заучивать. Индивидуальный подход и решение локальных задач по изучению
                                    профессионального языка в сжатые сроки также на высоте! Отмечу, что полученные знания не испарились после обучения,
                                    и я свободно читаю профессиональную литературу и интернет-источники на английском.
                                    Всяческого процветания и успехов школе!”
                                </p>
                                <div className='flex flex-col items-center gap-y-2'>
                                    <p className='text-base md:text-lg font-medium'>Василина Сенечкина</p>
                                    <div className='relative w-16 h-16'>
                                        <img alt='' src="./img/ot4.png" />
                                    </div>
                                </div>
                            </div>

                        </Carousel>
                    </div>
                </div>
            </div>


        )
    }
}

export default Reviews;