import React, { Component } from "react";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

class NavHeader extends Component {
  render() {
    return (
      <div data-aos="fade-down" className="fixed z-10">
        <div className="grid xl:grid-cols-3 bg-blue-600">
          <div className="hidden xl:grid justify-center content-center">
            <img
              className="object-cover h-9 w-96"
              src="./img/time_managment.png"
            />
          </div>

          <div className="grid justify-center content-center px-6 py-1.5 md:px-0 xl:py-0">
            <p className="text-center text-xs text-white">
              Первое занятие <span className="underline">БЕСПЛАТНО</span>!
              Запишитесь до конца месяца к топовым учителям
            </p>
          </div>

          <div className="hidden xl:grid justify-center content-center">
            <img
              className="object-cover h-9 w-96"
              src="./img/study_from_books.png"
            />
          </div>
        </div>

        <div className="hidden md:grid grid-cols-12 bg-white py-5">
          <nav className="col-start-2 xl:col-start-3 col-span-3">
            <ol className="flex flex-row gap-7 xl:gap-10">
              <Link
                className="text-sm xl:text-base text-gray-800"
                to="/"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Главная
              </Link>

              <li className="text-sm xl:text-base text-gray-800">
                <AnchorLink offset="180" href="#services">
                  Услуги
                </AnchorLink>
              </li>

              <li className="text-sm xl:text-base text-gray-800">
                <AnchorLink offset="180" href="#price">
                  Стоимость
                </AnchorLink>
              </li>

              <li className="hidden xl:block text-base text-gray-800">
                <AnchorLink offset="180" href="#reviews">
                  Отзывы
                </AnchorLink>
              </li>

              <li className="hidden xl:block text-base text-gray-800">
                <AnchorLink offset="180" href="#gallery">
                  Галерея
                </AnchorLink>
              </li>
              <li className="text-sm xl:text-base text-gray-800">
                <AnchorLink offset="180" href="#contact">
                  Контакты
                </AnchorLink>
              </li>
            </ol>
          </nav>

          <div className="flex justify-end col-end-12 xl:col-end-11 col-span-3">
            <div className="flex flex-row justify-center items-center gap-5">
              <div className="flex flex-row gap-2.5">
                <a target="_blank" href="https://vk.com/olessya_turbabina">
                  <img
                    className="hover:opacity-50 transition duration-300 ease-in-out"
                    src="./img/vk_icon.svg"
                  />
                </a>
                <a target="_blank" href="https://instagram.com/best_eng_school">
                  <img
                    className="hover:opacity-50 transition duration-300 ease-in-out"
                    src="./img/in_icon.svg"
                  />
                </a>
              </div>
              <p className="font-bold text-base text-gray-800">
                +7 (9873) 49 90 44
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NavHeader;
