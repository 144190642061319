import React, { Component } from 'react';

class Gallery extends Component {
    render() {
        return (
            <div id='gallery' className='relative flex flex-col gap-y-24 xl:gap-y-32'>
                <div data-aos="fade-up" className='flex flex-row justify-center'>
                    <h2 className='text-5xl font-extrabold text-gray-800'>Галерея</h2>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-4 xl:gap-8 xl:mx-72'>
                    <div data-aos="fade-up" className='h-96 bg-no-repeat bg-cover bg-center rounded-xl bg-[url("./img/IMG_2426.JPG")]'></div>
                    <div data-aos="fade-up" className='h-96 bg-no-repeat bg-cover bg-center rounded-xl bg-[url("./img/IMG_2423.JPG")]'></div>
                    <div data-aos="fade-up" className='h-96 bg-no-repeat bg-cover bg-center rounded-xl bg-[url("./img/IMG_2446.JPG")]'></div>
                    <div data-aos="fade-up" className='h-96 bg-no-repeat bg-cover bg-center rounded-xl bg-[url("./img/IMG_2422.JPG")]'></div>
                </div>
            </div>
        )
    }
}

export default Gallery