import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <div className='grid grid-cols-12 bg-stone-800 mt-32'>
                <div className='flex flex-col-reverse gap-y-4 md:gap-y-0 md:grid grid-cols-2 col-start-2 xl:col-start-3 col-span-10 md:col-end-12 xl:col-end-11 pt-6 pb-4 md:pt-8 md:pb-6 xl:pt-10 xl:pb-8'>
                    <div className='flex flex-col items-center md:items-start gap-y-1 md:gap-y-0.5'>
                        <p className='text-sm text-white opacity-70'>ИП Турбабина Олеся Дмитриевна</p>
                        <p className='text-sm text-white opacity-70'>ОГРНИП 317565800050300</p>
                    </div>

                    <div className='flex flex-col md:flex-row items-center justify-center md:justify-end gap-x-4 gap-y-4'>
                        <div className='flex flex-col gap-y-1 md:gap-y-0'>
                            <a href="tel:+79873499044" className='text-base text-white opacity-90 font-bold'>+7 (9873) 49 90 44</a>                
                        </div>
                        
                        <div className='flex flex-row gap-x-1.5'>
                            <a target="_blank" href="https://t.me/Turbabina">
                                <img className='h-5 hover:opacity-50 transition duration-300 ease-in-out' src="./img/Telegram.png" />
                            </a>

                            <a target="_blank" href="https://instagram.com/best_eng_school">
                                <img className='h-5 hover:opacity-50 transition duration-300 ease-in-out' src="./img/Instagram.png" />
                            </a>

                            <a target="_blank" href="https://www.youtube.com/@user-yn2fn2sn1r/featured">
                                <img className='h-5 hover:opacity-50 transition duration-300 ease-in-out' src="./img/yt.png" />
                            </a>
                        </div>
                    </div>
                </div>

                <hr className='col-start-2 xl:col-start-3 md:col-end-12 xl:col-end-11 col-span-10 opacity-40' />

                <div className='flex flex-col md:grid grid-cols-2 col-start-2 xl:col-start-3 md:col-end-12 xl:col-end-11 col-span-10 py-4'>
                    <div className='flex flex-col items-center md:items-start xl:flex-row gap-x-5'>
                        <Link
                            onClick={() => {
                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                            }}
                            className='text-sm text-white opacity-70 hover:opacity-90 transition duration-300 ease-in-out'
                            to="offer"
                        >
                            Оферта
                        </Link>
                        <Link
                            onClick={() => {
                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                            }}
                            className='text-sm text-white opacity-70 hover:opacity-90 transition duration-300 ease-in-out'
                            to="policy"
                        >
                            Политика конфиденциальности
                        </Link>
                        <Link onClick={() => {
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }}
                            className='text-sm text-white opacity-70 hover:opacity-90 transition duration-300 ease-in-out'
                            to="agreement"
                        >Согласие на обработку персональных данных
                        </Link>
                    </div>

                    <div className='flex flex-row items-center justify-center md:justify-end gap-x-4 mt-5 md:mt-0'>
                        <p className='text-sm text-white opacity-70'>Developed by <a className='text-blue-500 hover:text-blue-400 transition duration-300 ease-in-out hover:underline' target="_blank" href="https://t.me/g10z3r">Gefion Team</a></p>
                    </div>
                </div>

            </div>
        )
    }
}

export default Footer;