import React, { Component } from "react";

class Offer extends Component {
  render() {
    return (
      <div>
        <div className="grid gap-y-14 xl:mx-40">
          <div className="grid gap-y-8 px-12 mt-20 md:mt-32 xl:mt-48">
            <h1 className="text-4xl xl:text-5xl font-bold w-full text-center">
              Публичная оферта
            </h1>
            <p className="text-sm text-gray-800 w-full text-center">
              Настоящий документ в соответствии с законодательством Российской
              Федерации является публичной офертой Исполнителя и содержит все
              существенные условия, необходимые для заключения и исполнения
              договора возмездного оказания услуг. Публичная оферта вступает в
              силу с момента ее опубликования в
              информационно-телекоммуникационной сети «Интернет» на сайте
              <a href="http://olesya-turbabina.ru" className="text-blue-500">
                http://olesya-turbabina.ru
              </a>
              и/или размещения в открытом доступе в Помещении и действует до
              момента отзыва Публичной оферты Исполнителем. Публичная оферта
              может быть изменена/отозвана Исполнителем в одностороннем порядке
              без предварительного согласования и/или уведомления. В случае
              внесения изменений они вступают в силу с момента их опубликования
              в информационно-телекоммуникационной сети «Интернет» на сайте
              http://olesya-turbabina.ru и/или размещения в открытом доступе в
              Помещении, если иной срок вступления в силу не установлен
              Исполнителем. Внесение изменений/отзыв Публичной оферты не влечет
              за собой внесение соответствующих изменений/прекращение
              правоотношений, возникших между Исполнителем и Заказчиком до даты
              внесения изменений/отзыва Публичной оферты.
            </p>
          </div>

          <div className="grid gap-y-8 px-12">
            <h2 className="text-2xl font-bold text-center text-gray-800">
              1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
            </h2>
            <ul className="flex flex-col gap-y-1.5 text-sm text-gray-800 w-full xl:px-12">
              <li>
                <b>1.1.</b> Публичная оферта (Оферта) – предложение Исполнителя,
                адресованное неопределенному кругу лиц, заключить договор
                возмездного оказания услуг;
              </li>
              <li>
                <b>1.2.</b> Акцепт – полное и безоговорочное принятие условий
                Оферты, выраженное в оплате Услуг Исполнителя Заказчиком. Акцепт
                означает заключение между Исполнителем и Заказчиком договора
                возмездного оказания услуг на условиях, изложенных в Оферте;
              </li>
              <li>
                <b>1.3.</b> Договор – договор возмездного оказания услуг,
                заключенный между Заказчиком и Исполнителем в результате Акцепта
                Публичной оферты;
              </li>
              <li>
                <b>1.4.</b> Сайт – веб-сайт, размещенный в общем доступе по
                адресу{" "}
                <a href="http://olesya-turbabina.ru" className="text-blue-500">
                  http://olesya-turbabina.ru
                </a>
                ;
              </li>
              <li>
                <b>1.5.</b> Исполнитель – Индивидуальный предприниматель
                Турбабина Олеся Дмитриевна, ОГРНИП 317565800050300, ИНН
                564603147147;
              </li>
              <li>
                <b>1.6.</b> Субисполнитель - любое третье лицо, привлечённое
                Исполнителем для оказания услуг Выгодоприобретателю;
              </li>
              <li>
                <b>1.7.</b> Заказчик – физическое или юридическое лицо, или
                индивидуальный предприниматель, осуществившее Акцепт Оферты;
              </li>
              <li>
                <b>1.8.</b> Выгодоприобретатель – лицо, являющееся
                непосредственным получателем (пользователем) Услуг;
              </li>
              <li>
                <b>1.9.</b> Стороны – Исполнитель, Заказчик и
                Выгодоприобретатель;
              </li>
              <li>
                <b>1.10.</b> Услуги – перечень услуг, оказываемых Исполнителем
                и/или привлечёнными Исполнителем третьим лицами, размещенный на
                Сайте и/или в открытом доступе в Помещении и указанный в Оферте;
              </li>
              <li>
                <b>1.11.</b> Помещение - место оказания Услуг офлайн,
                расположенное по адресу: г. Оренбург, Северный проезд, 7.
              </li>
            </ul>
          </div>

          <div className="grid gap-y-8 px-12">
            <h2 className="text-2xl font-bold text-center text-gray-800">
              2. ПРЕДМЕТ ДОГОВОРА
            </h2>
            <ul className="flex flex-col gap-y-1.5 text-sm text-gray-800 w-full xl:px-12">
              <li>
                <b>2.1.</b> Исполнитель обязуется оказать Заказчику и/или
                Выгодоприобретателю Услуги на условиях, указанных в Оферте, а
                Заказчик обязуется оплатить оказываемые Исполнителем Услуги.{" "}
              </li>
              <li>
                <b>2.2.</b> Дополнительные условия оказания Услуг также могут
                содержаться и в иных документах, исходящих от Исполнителя.
              </li>
              <li>
                <b>2.3.</b> Исполнитель вправе по своему единоличному усмотрению
                привлекать к оказанию услуг Субисполнителей.
              </li>
            </ul>
          </div>

          <div className="grid gap-y-8 px-12">
            <h2 className="text-2xl font-bold text-center text-gray-800">
              3. ПОРЯДОК ОКАЗАНИЯ УСЛУГ
            </h2>
            <ul className="flex flex-col gap-y-1.5 text-sm text-gray-800 w-full xl:px-12">
              <li>
                <b>3.1.</b> Исполнитель оказывает Услуги онлайн и офлайн.
              </li>
              <li>
                <b>3.2.</b> Исполнитель оказывает Услуги в групповой и
                индивидуальной форме.
              </li>
              <li>
                <b>3.3.</b> Оказание Услуг онлайн осуществляется посредством
                сервисов Zoom, Skype, WhatsApp, Telegram по согласованию между
                Заказчиком и Выгодоприобретателем и/или Исполнителем.
              </li>
              <li>
                <b>3.4.</b> Оказание Услуг офлайн осуществляется в Помещении.
              </li>
              <li>
                <b>3.5.</b> Когда это применимо, Заказчик вправе выбрать, кем
                будут оказаны Услуги: Исполнителем или Субисполнителем.
              </li>
              <li>
                <b>3.6.</b> Исполнитель проводит первое пробное занятие
                бесплатно. Для записи на первое пробное занятие необходимо
                связаться с Исполнителем по телефонам, указанным на Сайте, либо
                заполнить форму обратной связи, размещённую на Сайте, либо
                посетить Помещение.
              </li>
              <li>
                <b>3.7.</b> График оказания Услуг в индивидуальной форме
                устанавливается Исполнителем по согласованию с Заказчиком и/или
                Выгодоприобретателем. График оказания Услуг в групповой форме
                устанавливается Исполнителем и доводится до сведения Заказчика
                и/или Выгодоприобретателя.
              </li>
              <li>
                <b>3.8.</b> Продолжительность 1 (одной) единицы Услуги
                “Индивидуальное занятие” составляет 60 (шестьдесят) минут.
              </li>
              <li>
                <b>3.9.</b> Продолжительность 1 (одной) единицы Услуги
                “Групповое занятие” составляет 60 (шестьдесят) минут.
              </li>
              <li>
                <b>3.10.</b> В случае опоздания Заказчика и/или
                Выгодоприобретателя время оказания Услуги сокращается на время
                опоздания. При наличии свободного времени Исполнитель вправе, но
                не обязан продлить время оказания Услуги.
              </li>
              <li>
                <b>3.11.</b> Заказчик и/или Выгодоприобретатель обязан известить
                Исполнителя о неявке не менее чем за 24 (двадцать четыре) часа
                до согласованного времени оказания Услуги.
              </li>
              <li>
                <b>3.12.</b> В случае соблюдения правила, установленного п. 3.11
                Договора, и последующего предоставления Исполнителю документа,
                выданного медицинской организацией и подтверждающего заболевание
                Заказчика и/или Выгодоприобретателя на весь период оказания
                Услуг, период оказания Услуг, установленный п. 4.5 Договора,
                продлевается на время, необходимое для посещения пропущенных
                занятий, в соответствии с графиком оказания Услуг.
              </li>
              <li>
                <b>3.13.</b> В случае несоблюдения правила, предусмотренного
                пунктом 3.11 Договора, Услуга считается надлежащим образом
                оказанной и подлежащей оплате.
              </li>
              <li>
                <b>3.14.</b> Правила, установленные пунктом 3.11 Договора, не
                применяются в случае возникновения симптомов заболевания менее
                чем за 24 (двадцать четыре) часа до согласованного времени
                оказания Услуг при условии предоставления документа, выданного
                медицинской организацией и подтверждающего заболевание Заказчика
                и/или Выгодоприобретателя. В данном случае применяются правила о
                продлении срока оказания Услуг, предусмотренные п. 3.12
                Договора.
              </li>
              <li>
                <b>3.15.</b> В случае невозможности Исполнителя оказать Услуги в
                соответствии с Графиком оказания Услуг Исполнитель уведомляет
                Заказчика и/или Выгодоприобретателя в разумный̆ срок с момента
                выявления обстоятельств, по причине которых возникла такая
                невозможность. Период оказания Услуг, установленный п. 4.5
                Договора, продлевается на срок невозможности оказания Услуг
                Исполнителем.
              </li>
              <li>
                <b>3.16.</b> Услуга “Групповое занятие” оказывается, если
                набрана группа не менее, чем из 3 (трех) человек. Максимальное
                количество участников группы не может превышать 8 (восемь)
                человек. Исполнитель вправе по своему усмотрению изменять
                персональный состав групп.
              </li>
              <li>
                <b>3.17.</b> Услуга “Групповое занятие” оказывается в период с
                01 сентября по 25 мая.
              </li>
              <li>
                <b>3.18.</b> Исполнитель предоставляет Заказчику и/или
                Выгодоприобретателю на время проведения занятия в пользование
                необходимые для проведения занятия учебные материалы и/или их
                копии. При необходимости Заказчик и/или Выгодоприобретатель
                вправе по своему усмотрению и за свой счет приобрести в
                собственность необходимые для проведения занятия учебные
                материалы и/или их копии.
              </li>
              <li>
                <b>3.19.</b> Акт об оказании услуг между сторонами не
                составляется. Услуга считается надлежащим образом оказанной в
                момент совершения юридически значимого действия, направленного
                на оказание Услуги Исполнителем Заказчику и/или
                Выгодоприобретателю.
              </li>
            </ul>
          </div>

          <div className="grid gap-y-8 px-12">
            <h2 className="text-2xl font-bold text-center text-gray-800">
              4. СТОИМОСТЬ УСЛУГ И ПОРЯДОК ОПЛАТЫ
            </h2>
            <ul className="flex flex-col gap-y-1.5 text-sm text-gray-800 w-full xl:px-12">
              <li>
                <b>4.1.</b> Стоимость Услуг составляет:
              </li>
              <li>
                <b>4.1.1.</b> групповое занятие при оказании Услуг Исполнителем
                - 800 (восемьсот) рублей 00 копеек;
              </li>
              <li>
                <b>4.1.2.</b> групповое занятие при оказании Услуг
                Субисполнителем - 600 (шестьсот) рублей 00 копеек за 1 (одно)
                занятие;
              </li>
              <li>
                <b>4.1.3.</b> индивидуальное занятие по направлению “Общий
                английский язык” при оказании Услуг Исполнителем - 2000 (две
                тысячи) рублей 00 копеек за 1 (одно) занятие;
              </li>
              <li>
                <b>4.1.4.</b> индивидуальное занятие по направлению “Общий
                английский язык” при оказании Услуг Субисполнителем - 1100 (одна
                тысяча сто) рублей 00 копеек за 1 (одно) занятие;
              </li>
              <li>
                <b>4.1.5.</b> индивидуальное занятие по направлению “Подготовка
                к сдаче Основного государственного экзамена (ОГЭ), Единого
                государственного экзамена (ЕГЭ)” при оказании Услуг Исполнителем
                - 2500 (две тысячи пятьсот) рублей 00 копеек за 1 (одно)
                занятие;
              </li>
              <li>
                <b>4.1.6.</b> индивидуальное занятие по направлению “Подготовка
                к сдаче Основного государственного экзамена (ОГЭ), Единого
                государственного экзамена (ЕГЭ)” при оказании Услуг
                Субисполнителем - 1500 (одна тысяча пятьсот) рублей 00 копеек за
                1 (одно) занятие;
              </li>
              <li>
                <b>4.1.7.</b> индивидуальное занятие по направлению “Подготовка
                к сдаче международного экзамена (IELTS, TOEFL)” при оказании
                Услуг Исполнителем - 3000 (три тысячи) рублей 00 копеек за 1
                (одно) занятие;
              </li>
              <li>
                <b>4.2.</b> Стоимость Услуг действительна на момент их оплаты
                Заказчиком. Стоимость Услуг может быть изменена Исполнителем по
                своему усмотрению в любое время в одностороннем порядке.
              </li>
              <li>
                <b>4.3.</b> Исполнитель предоставляет Услуги на условиях полной
                предварительной оплаты стоимости Услуг.
              </li>
              <li>
                <b>4.4.</b> Заказчик осуществляет предварительную оплату 8
                (восьми) занятий по выбранному Заказчиком направлению.
              </li>
              <li>
                <b>4.5.</b> После осуществления Заказчиком предоплаты в
                зависимости от выбранной Заказчиком формы оказания Услуг:
              </li>
              <li>
                <b>4.5.1.</b> Исполнитель обязуется организовать оказание Услуг
                по проведению 8 (восьми) индивидуальных занятий в течение 30
                (тридцати) календарных дней с даты внесения предварительной
                оплаты, а Заказчик и/или Выгодоприобретатель обязуется посетить
                8 (восемь) индивидуальных занятий в течение 30 (тридцати)
                календарных дней с даты внесения предварительной оплаты.
              </li>
              <li>
                <b>4.6.</b> В силу п. 2 ст. 429.4 Гражданского кодекса РФ, п. 33
                постановления Пленума Верховного Суда РФ от 25.12.2018 №49 по
                истечении срока оказания услуг, указанного в п. 4.5 Договора,
                обязательства Исполнителя считаются исполненными, а Договор –
                прекратившим действие независимо от объема фактически полученных
                Выгодоприобретателем Услуг.
              </li>
              <li>
                <b>4.7.</b> Заказчик осуществляет оплату Услуг посредством
                безналичного перевода денежных средств на расчетный счет
                Исполнителя согласно реквизитам, указанным в Договоре.
              </li>
              <li>
                <b>4.8.</b> Все издержки, связанные с перечислением денежных
                средств по Договору на расчетный счет Исполнителя, несет
                Заказчик.
              </li>
            </ul>
          </div>

          <div className="grid gap-y-8 px-12">
            <h2 className="text-2xl font-bold text-center text-gray-800">
              5. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
            </h2>
            <ul className="flex flex-col gap-y-1.5 text-sm text-gray-800 w-full xl:px-12">
              <li>
                <b>5.1.</b> Договор может быть изменен или расторгнут по
                соглашению Сторон.
              </li>
              <li>
                <b>5.2.</b> В случае неисполнения или ненадлежащего исполнения
                Сторонами обязательств по договору Стороны несут
                ответственность, предусмотренную Договором и законодательством
                Российской Федерации.
              </li>
              <li>
                <b>5.3.</b> Все споры и разногласия, возникающие между сторонами
                в рамках Договора или в связи с ним, в том числе касающиеся его
                заключения, изменения, исполнения, нарушения, расторжения или
                признания недействительным, подлежат рассмотрению судом по месту
                нахождения Исполнителя.
              </li>
              <li>
                <b>5.4.</b> В случае необходимости получения социального вычета
                за Услуги Заказчику необходимо распечатать, заполнить и
                приложить к заявлению на получение социального вычета заявление
                о присоединении к Публичной оферте (Приложение №1 к Публичной
                оферте).
              </li>
            </ul>
          </div>

          <div className="grid gap-y-8 px-12">
            <h2 className="text-2xl font-bold text-center text-gray-800">
              6. РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ
            </h2>
            <ul className="flex flex-col gap-y-1.5 text-sm text-gray-800 w-full xl:px-12">
              <li>Исполнитель: </li>
              <li>Индивидуальный предприниматель</li>
              <li>Турбабина Олеся Дмитриевна</li>
              <li>ОГРНИП 317565800050300,</li>
              <li>ИНН 564603147147</li>
              <li>р/с 40802810146000015836</li>
              <li>в ПАО «СБЕРБАНК»</li>
              <li>к/с 30101810600000000601</li>
              <li>БИК 045354601</li>
              <li>
                Сайт:{" "}
                <a href="http://olesya-turbabina.ru" className="text-blue-500">
                  http://olesya-turbabina.ru
                </a>
              </li>
              <li>
                Почта:{" "}
                <span className="text-blue-500">
                  olesiaturbabina@icloud.com
                </span>
              </li>
            </ul>
          </div>

          <div className="grid gap-y-8 px-12">
            <h2 className="text-2xl font-bold text-center text-gray-800">
              ЗАЯВЛЕНИЕ О ПРИСОЕДИНЕНИИ К ПУБЛИЧНОЙ ОФЕРТЕ
            </h2>
            {/* <table className="table-auto">
              <tbody>
                <tr className="border border-gray-300 ">
                  <td className="p-2 border-l border-r border-gray-300 w-1/2">
                    Фамилия, Имя, Отчество
                  </td>
                  <td className="w-1/2"></td>
                </tr>
                <tr className="border border-gray-300 ">
                  <td className="p-2 border-l border-r border-gray-300 w-1/2">
                    Дата рождения
                  </td>
                  <td className="w-1/2"></td>
                </tr>
                <tr className="border border-gray-300 ">
                  <td className="p-2 border-l border-r border-gray-300 w-1/2">
                    Адрес проживания
                  </td>
                  <td className="w-1/2"></td>
                </tr>
                <tr className="border border-gray-300 ">
                  <td className="p-2 border-l border-r border-gray-300 w-1/2">
                    Паспортные данные
                  </td>
                  <td className="w-1/2"></td>
                </tr>
              </tbody>
            </table> */}
            <p className="text-sm text-gray-800 w-full text-center">
              Настоящим заявляет о полном и безоговорочном принятии условий
              Публичной оферты, опубликованной в
              информационно-телекоммуникационной сети «Интернет» на сайте
              <a href="http://olesya-turbabina.ru" className="text-blue-500">
                http://olesya-turbabina.ru
              </a>{" "}
              и/или размещённой в открытом доступе по адресу г. Оренбург,
              Северный проезд, 7, и принимает на себя обязательства следовать
              положениям, указанным в Публичной оферте и приложениях к ней.
            </p>

            {/* <div className="flex flex-row gap-x-1">
              <p className="text-sm text-gray-800">Заказчик</p>
              <div className="w-56 border-b border-gray-300"></div>
              <p className="text-sm text-gray-800">/</p>
              <div className="w-56 border-b border-gray-300"></div>
            </div> */}

            <div className="flex flex-row justify-center w-full">
              <a
                target="_blank"
                href="http://olesya-turbabina.ru/%D0%9F%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B51.docx"
                className="font-medium text-sm xl:text-base text-white bg-blue-500 hover:bg-blue-600 px-4 py-3 md:px-5 md:py-3.5 rounded-lg transition duration-300 ease-in-out"
              >
                Скачать
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Offer;
