import React, { Component } from 'react';
import { YMaps, Map, ZoomControl, Placemark } from '@pbe/react-yandex-maps';
import emailjs from '@emailjs/browser';

class Contacts extends Component<{ formModal: boolean, openModal: any, closeModal: any }> {
    constructor(props: any) {
        super(props);
    }

    render() {
        function sendEmail(e: React.FormEvent<HTMLFormElement>) {
            e.preventDefault();


            emailjs.sendForm('service_i09k67x', 'template_e01nwlb', e.currentTarget, '_xjjan2Up2ftXxueh')
                .then((result) => {
                    // alert("Заявка успешно отправлена!");
                }, (error) => {
                    alert(error.text);
                });

            e.currentTarget.reset();
        }

        return (
            <div id='contact' className='grid xl:grid-cols-12'>
                <div className='col-start-1 xl:col-start-3 col-end-12 xl:col-end-11 col-span-10 w-full'>
                    <div className='flex flex-col xl:flex-row items-center'>
                        <div data-aos="fade-up" className='flex flex-col gap-y-8'>
                            <h2 className='text-5xl font-extrabold text-gray-800'>Контакты</h2>

                            <div className='flex flex-col gap-y-2'>
                                <div className='flex flex-row items-center gap-x-2.5'>
                                    <img className='h-4' src="./img/device-mobile.png" />
                                    <p className='text-base text-gray-800'>+7 (9873) 49 90 44</p>
                                </div>

                                <div className='flex flex-row items-center gap-x-2.5'>
                                    <img className='h-4' src="./img/mention.png" />
                                    <p className='text-base text-gray-800'>olesiaturbabina@icloud.com</p>
                                </div>

                                <div className='flex flex-row items-center gap-x-2.5'>
                                    <img className='h-4' src="./img/organization.png" />
                                    <p className='text-base text-gray-800'>Россия, Оренбург, Северный Проезд 7</p>
                                </div>
                            </div>

                            <form onSubmit={sendEmail} className='flex flex-col gap-y-3'>
                                <input
                                    name="user_phone"
                                    className='border border-gray-300 py-3.5 px-4 rounded-lg w-full md:w-3/5 xl:w-3/4 text-base outline-1 focus:outline-blue-500 hover:border-blue-400 transition duration-300 ease-in-out' type="text" placeholder='Телефон *' />
                                <p className='text-sm text-gray-800 opacity-80 w-full md:w-3/5 xl:w-4/5'>Нажимая на кнопку «Отправить», я выражаю своё конкретное, информированное, сознательное, предметное, однозначное, добровольное, безоговорочное согласие на обработку моих персональных данных в соответствии с Политикой конфиденциальности.</p>
                                <button
                                    onClick={() => this.props.openModal()}
                                    type="submit"
                                    className='w-full md:w-3/5 xl:w-3/4 font-medium text-sm xl:text-base text-white bg-blue-500 hover:bg-blue-600 px-4 py-3 md:px-5 md:py-3.5 rounded-lg transition duration-300 ease-in-out'
                                >
                                    Отправить
                                </button>
                            </form>
                        </div>

                        <div data-aos="fade-up"  className='w-full h-56 xl:h-96 mt-5 xl:mt-0'>
                            <YMaps>
                                <Map
                                    width="100%"
                                    height="100%" defaultState={{ center: [51.826063, 55.154334], zoom: 17 }}
                                >
                                    <Placemark defaultGeometry={[51.826063, 55.154334]} />
                                    <ZoomControl />
                                </Map>
                            </YMaps>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contacts;