import React, { Component } from 'react';

class Policy extends Component {
    render() {
        return (
            <div className="grid gap-y-14 xl:mx-40">
                {/* <div className="grid gap-y-8 px-12 mt-20 md:mt-32 xl:mt-48">
                    <h1 className="text-4xl xl:text-5xl font-bold w-full text-center">Политика конфиденциальности</h1>
                    <p className="text-sm text-gray-800 w-full text-center">
                        Использование Пользователем Сайта <a href="http://olesya-turbabina.ru" className="text-blue-500">http://olesya-turbabina.ru</a> или посещение Школы английского языка “English school of Olesya Turbabina” по адресу: г. Оренбург, Северный проезд, 7 означает полное и безоговорочное согласие с Политикой конфиденциальности и условиями обработки персональных данных Пользователя.
                    </p>
                </div> */}

                <div className="grid gap-y-8 px-12">
                    <h2 className="text-2xl font-bold text-center text-gray-800">
                        1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
                    </h2>
                    <ul className="flex flex-col gap-y-1.5 text-sm text-gray-800 w-full xl:px-12">
                        <li><b>1.1.</b> Сайт – веб-сайт, размещенный в сети Интернет по адресу: <a href="http://olesya-turbabina.ru" className="text-blue-500">http://olesya-turbabina.ru</a></li>
                        <li><b>1.2.</b> Оператор – Владелец Сайта, его работники, уполномоченные им лица и/или лица, состоящие в договорных отношениях с Владельцем Сайта, имеющие право на управление Сайтом, задачей которых является обеспечение работы Сайта и защита персональных данных Пользователей, а также определение целей обработки таких данных, их состава, действий (операций), совершаемых с ними.</li>
                        <li><b>1.3.</b> Владелец Сайта – Индивидуальный предприниматель Турбабина Олеся Дмитриевна, ОГРНИП 317565800050300, ИНН 564603147147.</li>
                        <li><b>1.4.</b> Услуги – перечень услуг Исполнителя, размещенный на Сайте;</li>
                        <li><b>1.5.</b> Пользователь – лицо, использующее Сайт посредством сети Интернет, в том числе Заказчик, Выгодоприобретатель.</li>
                        <li><b>1.6.</b> Заказчик – юридическое лицо, индивидуальный предприниматель или физическое лицо, заключившее договор оказания Услуг с Владельцем Сайта.</li>
                        <li><b>1.7.</b> Выгодоприобретатель – лицо, являющееся непосредственным получателем (пользователем) Услуг.</li>
                        <li><b>1.8.</b> Персональные данные — любая информация, относящаяся прямо или косвенно к определенному физическому лицу (субъекту персональных данных) в соответствии с Федеральным законом от 27.07.2006 №152-ФЗ «О персональных данных» (далее – Закон о персональных данных).</li>
                        <li><b>1.9.</b> Субъект персональных данных – физическое лицо, которое прямо или косвенно определено или определяемо с помощью персональных данных.</li>
                        <li><b>1.10.</b> Закон о персональных данных – Федеральный закон «О персональных данных» от 27.07.2006 №152-ФЗ.</li>
                        <li><b>1.11.</b> Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление доступа), обезличивание, блокирование, удаление, уничтожение персональных данных.</li>
                        <li><b>1.12.</b> Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники.</li>
                        <li><b>1.13.</b> Распространение персональных данных – действия, направленные на раскрытие персональных данных неопределенному кругу лиц.</li>
                        <li><b>1.14.</b> Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</li>
                        <li><b>1.15.</b> Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</li>
                        <li><b>1.16.</b> Уничтожение персональных данных – действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных.</li>
                        <li><b>1.17.</b> Обезличивание персональных данных – действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных.</li>
                        <li><b>1.18.</b> Конфиденциальность персональных данных — обязательное для соблюдения Администрацией Сайта или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</li>
                        <li><b>1.19.</b> Cookies — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего веб сайта.</li>
                        <li><b>1.20.</b> IP-адрес — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</li>
                        <li><b>1.21.</b> Помещение - место оказания Услуг офлайн, расположенное по адресу: г. Оренбург, Северный проезд, 7.</li>
                    </ul>
                </div>

                <div className="grid gap-y-8 px-12">
                    <h2 className="text-2xl font-bold text-center text-gray-800">
                        2. СВЕДЕНИЯ О РЕАЛИЗУЕМЫХ ОПЕРАТОРОМ ТРЕБОВАНИЯХ К ЗАЩИТЕ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </h2>
                    <ul className="flex flex-col gap-y-1.5 text-sm text-gray-800 w-full xl:px-12">
                        <li>Оператором предприняты следующие меры, направленные на защиту персональных данных:</li>
                        <li><b>2.1.</b>издан документ, определяющий политику Оператора в отношении обработки персональных данных;</li>
                        <li><b>2.2.</b>существляется внутренний контроль соответствия обработки персональных данных ФЗ-152 и принятым в соответствии с ним нормативным правовым актам, требованиям к защите персональных данных, политике Оператора в отношении обработки персональных данных, локальным актам Оператора;</li>
                        <li><b>2.3.</b>работники Оператора, непосредственно осуществляющие обработку персональных данных, ознакомлены с положениями законодательства Российской Федерации о персональных данных, в том числе с требованиями к защите персональных данных, документами, определяющими политику организации в отношении обработки персональных данных, локальными актами по вопросам обработки персональных данных;</li>
                        <li><b>2.4.</b>обеспечивается учет машинных носителей персональных данных;</li>
                        <li><b>2.5.</b>обеспечивается восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</li>
                        <li><b>2.6.</b>имеются надежные препятствия для несанкционированного проникновения в помещения информационной системы и хранилище носителей информации;</li>
                        <li><b>2.7.</b>применяются различные организационные и технические меры по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных;</li>
                        <li><b>2.8.</b>определены места хранения персональных данных (материальных носителей);</li>
                        <li><b>2.9.</b>определен перечень лиц, осуществляющих обработку персональных данных и имеющих к ним доступ;</li>
                        <li><b>2.10.</b>обеспечен учет материальных носителей;</li>
                        <li><b>2.11.</b>исключена возможность неконтролируемого проникновения или пребывания посторонних лиц в помещения, где ведется работа с персональными данными;</li>
                        <li><b>2.12.</b>обеспечена сохранность носителей персональных данных и средств защиты информации.</li>
                    </ul>
                </div>

                <div className="grid gap-y-8 px-12">
                    <h2 className="text-2xl font-bold text-center text-gray-800">
                        3. ОБЩИЕ ПОЛОЖЕНИЯ
                    </h2>
                    <ul className="flex flex-col gap-y-1.5 text-sm text-gray-800 w-full xl:px-12">
                        <li><b>3.1.</b>Политика конфиденциальности применяется только к действиям Оператора, не регулирует и не формирует ответственность за действия третьих лиц.</li>
                        <li><b>3.2.</b>Оператор не проверяет достоверность персональных данных, предоставляемых Пользователем.</li>
                        <li><b>3.3.</b>Правовым основанием обработки персональных данных является согласие субъектов персональных данных на обработку их персональных данных, договоры возмездного оказания услуг.</li>
                        <li><b>3.4.</b>Правовым основанием обработки персональных данных является также совокупность нормативных правовых актов, во исполнение которых и в соответствии с которыми Оператор осуществляет обработку персональных данных, в том числе Гражданский кодекс Российской Федерации, иные нормативные правовые акты, регулирующие отношения, связанные с деятельностью Оператора.</li>
                        <li><b>3.5.</b>Использование Сайта с помощью веб-браузера, который принимает данные из cookies, означает выражение согласия Пользователя с тем, что Оператор может собирать и обрабатывать данные из cookies в целях, предусмотренных настоящей Политикой, а также на передачу данных из cookies третьим лицам в случаях, перечисленных в настоящей Политике.</li>
                    </ul>
                </div>

                <div className="grid gap-y-8 px-12">
                    <h2 className="text-2xl font-bold text-center text-gray-800">
                        4. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ
                    </h2>
                    <ul className="flex flex-col gap-y-1.5 text-sm text-gray-800 w-full xl:px-12">
                        <li><b>4.1.</b> Политика конфиденциальности устанавливает обязательства Оператора по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь, Заказчик или Выгодоприобретатель предоставляют Оператору.</li>
                        <li><b>4.2.</b> Персональные данные, разрешённые к обработке в рамках Политики конфиденциальности, передаются Пользователем Оператору путем заполнения формы, предоставленной Оператором, и включают в себя следующую информацию:</li>
                        <li><b>4.2.1.</b> фамилия, имя, отчество;</li>
                        <li><b>4.2.2.</b> дата рождения;</li>
                        <li><b>4.2.3.</b> паспортные данные;</li>
                        <li><b>4.2.4.</b> номер телефона;</li>
                        <li><b>4.2.5.</b> почтовый адрес.</li>
                        <li><b>4.3.</b> Персональные данные, разрешённые к обработке в рамках Политики конфиденциальности, передаются Заказчиком и/или Выгодоприобретателем Оператору путем заполнения формы, предоставленной Оператором и предоставления информации Оператору, и включают в себя следующую информацию:</li>
                        <li><b>4.3.1.</b> фамилия, имя, отчество;</li>
                        <li><b>4.3.2.</b> дата рождения;</li>
                        <li><b>4.3.3.</b> паспортные данные;</li>
                        <li><b>4.3.4.</b> номер телефона;</li>
                        <li><b>4.4.</b> почтовый адрес.</li>
                        <li><b>4.5.</b> Отключение Cookies может повлечь невозможность доступа к частям Сайта. Сайт осуществляет сбор статистики об IP-адресах Пользователей. Данная информация используется с целью выявления и решения технических проблем Сайта.</li>
                        <li><b>4.6.</b> Любая иная персональная информация, не оговоренная выше, подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 7.2. и 7.3. Политики конфиденциальности.</li>
                    </ul>
                </div>
            </div >
        )
    }
}

export default Policy;