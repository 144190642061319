import React, { Component } from "react";
import Contacts from "./Contacts";
import Gallery from "./Gallery";
import Home from "./Home";
import Price from "./Price";
import Reviews from "./Reviews";
import Services from "./Services";


class Main extends Component {
    state = {
        modalIsOpen: false,
        phone: ''
    };

    constructor(props: any) {
        super(props);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({
            ['modalIsOpen']: true
        });
    }

    closeModal() {
        this.setState({
            ['modalIsOpen']: false
        });
    }

    submForm() {
        this.setState({
            ['modalIsOpen']: false
        });

        alert("Заявка успешно отправлена!");
    }

    render() {
        return (
            <div className='grid gap-y-20 xl:gap-y-40'>
                <div className='grid gap-y-20 xl:gap-y-40 bg-slate-100 pb-24'>
                    <Home />
                    <Services />
                </div>

                <div className="grid gap-y-20 xl:gap-y-40 mx-5">
                    <Price />
                    <Reviews />
                    <Gallery />
                    <Contacts
                        closeModal={this.closeModal}
                        openModal={this.openModal}
                        formModal={this.state.modalIsOpen} 
                    />
                </div>


                <div onClick={() => this.closeModal()} className={`fixed z-50 w-full h-full bg-black bg-opacity-30 ${this.state.modalIsOpen ? "block" : "hidden"}`}>
                    <div className="flex flex-col justify-center items-center overflow-auto">
                        <div className="relative flex flex-col items-center gap-y-5 px-10 pt-10 pb-5 w-1/3 h-96 bg-white mt-20 rounded-tr-xl rounded-tl-xl">
                            <h4 className="text-3xl font-bold text-center px-10">Согласие на обработку персональных данных</h4>
                            <p className="text-sm text-gray-800 w-full text-center overflow-y-auto">
                                Я ознакомлен и согласен с тем, что в соответствии с требованиями Федерального закона от 27 июля 2006 г. №152-ФЗ «О персональных данных» будет осуществляться обработка предоставляемых мною персональных данных, в том числе фамилии, имени, отчества (при наличии), даты рождения, паспортных данных, номера телефона, почтового адреса, а также иных предоставляемых мною данных.
                                <br /><br />
                                Одновременно подтверждаю свое согласие на обработку и осуществление Индивидуальным предпринимателем Турбабиной Олесей Дмитриевной (ИНН 564603147147, ОГРНИП 317565800050300) (далее – «Оператор») следующих действий с моими персональными данными: сбор, запись, систематизация, накопление, хранение, извлечение, уточнение, обновление, изменение, использование, обезличивание, блокирование, удаление, уничтожение, а также передача (предоставление) третьим лицам, привлеченным Оператором, моих персональных данных. Настоящее согласие не устанавливает предельных сроков обработки персональных данных.
                                <br /><br />
                                Персональные данные предоставляются мною и обрабатываются Оператором для достижения следующих целей: предоставление Субъекту персональных данных возможности осуществления обратной связи с Оператором; регистрация Субъекта персональных данных в базе данных Оператора с последующим направлением почтовых сообщений, смс-уведомлений, звонков, в том числе рекламного, справочного, маркетингового содержания от Оператора; направление Субъекту персональных данных информационных и новостных рассылок, приглашений на мероприятия Оператора, партнеров Оператора и другой информации рекламно-новостного содержания; подтверждение личности Субъекта персональных данных; для предоставления Субъекту персональных данных консультаций по вопросам, касающимся оказываемых услуг; осуществление маркетинговой деятельности; оценка работы Сайта; оказание услуг; повышение качества оказания услуг, качества работы Сайта; обработка претензий и обращений Субъекта персональных данных.
                                <br /><br />
                                Я понимаю и соглашаюсь с тем, что Оператор не проверяет достоверность персональных данных, предоставляемых мной, не имеет возможности оценивать мою дееспособность, и исходит из того, что я предоставляю достоверные персональные данные и поддерживаю такие данные в актуальном состоянии.
                                <br /><br />
                                Настоящее согласие на обработку персональных данных действует с момента его предоставления Оператору и является бессрочным. Согласие может быть отозвано мной в любое время путем направления Оператору заявления в простой письменной форме по адресу: 460048 г. Оренбург, ул. Фронтовиков, д. 6/1, кв. 413 или на адрес электронной почты: olesiaturbabina@icloud.com.
                                <br /><br />
                                Персональные данные подлежат хранению в течение сроков, установленных законодательством Российской Федерации. Персональные данные уничтожаются по достижении целей обработки персональных данных, при прекращении деятельности Оператором, на основании письменного обращения Субъекта персональных данных с требованием о прекращении обработки его персональных данных (Оператор прекращает обработку таких персональных данных в течение 10 (десяти) рабочих дней, о чем будет направлено письменное уведомление субъекту персональных данных в течение 10 (десяти) рабочих дней).
                                <br /><br />
                                Оператор вправе привлекать для обработки персональных данных Субъекта персональных данных субподрядчиков, а также вправе передавать персональные данные для обработки своим аффилированным лицам, обеспечивая при этом принятие такими субподрядчиками и аффилированными лицами соответствующих обязательств в части сохранения конфиденциальности персональных данных.
                            </p>
                        </div>
                        <div className="flex flex-row justify-center pb-6 bg-white w-1/3 rounded-br-xl rounded-bl-xl">
                            <button
                                onClick={() => this.submForm()}
                                className='w-full md:w-3/5 xl:w-3/4 font-medium text-sm xl:text-base text-white bg-blue-500 hover:bg-blue-600 px-4 py-3 md:px-5 md:py-3.5 rounded-lg transition duration-300 ease-in-out'
                            >
                                Отправить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Main;