import React, { Component } from "react";

class Home extends Component<{}, { experience: number; students: number }> {
  constructor(props: any) {
    super(props);
    this.state = {
      students: 0,
      experience: 0,
    };
  }

  render() {
    const experienceTimeout = setTimeout(() => {
      if (this.state.experience < 25) {
        this.setState({
          ...this.state,
          experience: this.state.experience + 1,
        });
      } else {
        clearTimeout(experienceTimeout);
      }
    }, 1000);

    const studentsTimeout = setTimeout(() => {
      if (this.state.students < 3000) {
        this.setState({
          ...this.state,
          students: this.state.students + 1,
        });
      } else {
        clearTimeout(studentsTimeout);
      }
    }, 1);

    return (
      <div className="relative grid grid-cols-12 items-center py-8 md:py-10 mt-12 md:mt-24 xl:mt-32">
        <div data-aos="fade-up" className="absolute w-full md:w-2/4 h-full">
          <div className="flex flex-col items-center md:items-end justify-end md:justify-center md:ml-20 mt-12 md:mt-36 xl:mt-40 w-full h-full">
            <img
              alt=""
              src="./img/signature.svg"
              className="h-16 md:h-16 xl:h-auto"
            />
          </div>
        </div>

        <div
          data-aos="fade-right"
          className="flex flex-col gap-y-6 col-start-2 md:col-start-2 xl:col-start-3 col-span-10 md:col-span-6 xl:col-span-3"
        >
          <div className="flex flex-col gap-y-3 xl:gap-y-4">
            <div className="xl:mb-2">
              <img alt="" src="./img/LOGO.png" className="h-16 xl:h-24" />
            </div>
            <h1 className="text-5xl xl:text-6xl font-extrabold leading-tight text-gray-800">
              Курсы
              <br />
              Английского
            </h1>
            <p className="text-sm xl:text-base text-gray-800">
              Школа английского языка English school of Olesya Turbabina была
              основана 8 августа 2008 года. За это время прошли обучение более
              3000 студентов из:
            </p>
          </div>
          <div className="w-full">
            <div className="items-wrap gap-x-5 md:gap-x-0">
              <div className="items marquee reverce gap-x-5 md:gap-x-0">
                <img
                  src="./img/ru_flag.png"
                  className="item h-6 rounded-[3px]"
                />
                <img
                  src="./img/israel_flag.png"
                  className="item h-6 rounded-[3px]"
                />
                <img
                  src="./img/fr_flag.png"
                  className="item h-6 rounded-[3px]"
                />
                <img
                  src="./img/gb_flag.png"
                  className="item h-6 rounded-[3px]"
                />
                <img
                  src="./img/it_flag.png"
                  className="item h-6 rounded-[3px]"
                />
                <img
                  src="./img/ug_flag.png"
                  className="item h-6 rounded-[3px]"
                />
                <img
                  src="./img/nl_flag.png"
                  className="item h-6 rounded-[3px]"
                />
                <img
                  src="./img/gr_flag.png"
                  className="item h-6 rounded-[3px]"
                />
              </div>
              <div aria-hidden="true" className="items marquee reverce gap-x-5 md:gap-x-0">
                <img
                  src="./img/ru_flag.png"
                  className="item h-6 rounded-[3px]"
                />
                <img
                  src="./img/israel_flag.png"
                  className="item h-6 rounded-[3px]"
                />
                <img
                  src="./img/fr_flag.png"
                  className="item h-6 rounded-[3px]"
                />
                <img
                  src="./img/gb_flag.png"
                  className="item h-6 rounded-[3px]"
                />
                <img
                  src="./img/it_flag.png"
                  className="item h-6 rounded-[3px]"
                />
                <img
                  src="./img/ug_flag.png"
                  className="item h-6 rounded-[3px]"
                />
                <img
                  src="./img/nl_flag.png"
                  className="item h-6 rounded-[3px]"
                />
                <img
                  src="./img/gr_flag.png"
                  className="item h-6 rounded-[3px]"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-x-3.5">
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfdopz-QITu5Z8nzJ2L3UdbZde4rrXif4lzTTKyWvGJxn1PnA/viewform"
              className="font-medium text-sm xl:text-base text-white bg-blue-500 hover:bg-blue-600 px-4 py-3 md:px-5 md:py-3.5 rounded-lg transition duration-300 ease-in-out"
            >
              Пройти тест
            </a>

            <a
              target="_blank"
              href="https://www.youtube.com/channel/UC9STMkcrtfcQxRJJYPokI0A"
              className="group flex flex-row items-center gap-x-2 font-medium text-sm xl:text-base text-gray-800 px-4 py-3 md:px-5 md:py-3.5"
            >
              YouTube
              <img
                alt=""
                className="group-hover:rotate-45 transition duration-300 ease-in-out"
                src="./img/arrow-up-right.svg"
              />
            </a>
          </div>

          <div className="block md:hidden">
            <img alt="" className="rounded-2xl" src="./img/ol_main.png" />
          </div>

          <div className="flex flex-row gap-x-28 md:mt-10 xl:mt-14">
            <div className="flex flex-col gap-y-0.5 w-32">
              <h5 className="font-extrabold text-3xl xl:text-4xl text-gray-800">
                {this.state.students}+
              </h5>
              <p className="text-sm text-gray-600 leading-snug">
                Счастливых
                <br />
                Выпускников
              </p>
            </div>

            <div className="flex flex-col gap-y-0.5">
              <h5 className="font-extrabold text-3xl xl:text-4xl text-gray-800">
                {this.state.experience}+
              </h5>
              <p className="text-sm text-gray-600 leading-snug">
                Лет
                <br />
                Опыта
              </p>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-left"
          className="relative z-0 hidden md:flex justify-end md:col-end-12 xl:col-end-11 col-span-3"
        >
          <img alt="" className="rounded-2xl" src="./img/ol_main.png" />
        </div>
      </div>
    );
  }
}

export default Home;
