import React from 'react';
import './App.css';
import 'reactjs-popup/dist/index.css';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HashRouter } from 'react-router-dom'
import Offer from './Offer';
import Layout from './Layout';
import Main from './Main';
import Policy from './Policy';
import Agreement from './Agreement';

function App() {
  return (
    <div className="App">
      <HashRouter>        
          <Routes>
            <Route path="/" element={ <Layout />}>
              <Route index element={ <Main />} />
              <Route path="policy" element={ <Policy />} />
              <Route path="agreement" element={ <Agreement />} />
              <Route path="offer" element={ <Offer />} />
            </Route>
          </Routes>        
      </HashRouter>

    </div>
  );
}

export default App;
