import React, { Component } from 'react';

class Agreement extends Component {
    render() {
        return (
            <div className="grid gap-y-14 xl:mx-40">
                <div className="grid gap-y-8 px-12 mt-20 md:mt-32 xl:mt-48">
                    <h1 className="text-4xl xl:text-5xl font-bold w-full text-center">Согласие на обработку персональных данных</h1>
                    <p className="text-sm text-gray-800 w-full text-center">
                        Я ознакомлен и согласен с тем, что в соответствии с требованиями Федерального закона от 27 июля 2006 г. №152-ФЗ «О персональных данных» будет осуществляться обработка предоставляемых мною персональных данных, в том числе фамилии, имени, отчества (при наличии), даты рождения, паспортных данных, номера телефона, почтового адреса, а также иных предоставляемых мною данных.
                        <br /><br />
                        Одновременно подтверждаю свое согласие на обработку и осуществление Индивидуальным предпринимателем Турбабиной Олесей Дмитриевной (ИНН 564603147147, ОГРНИП 317565800050300) (далее – «Оператор») следующих действий с моими персональными данными: сбор, запись, систематизация, накопление, хранение, извлечение, уточнение, обновление, изменение, использование, обезличивание, блокирование, удаление, уничтожение, а также передача (предоставление) третьим лицам, привлеченным Оператором, моих персональных данных. Настоящее согласие не устанавливает предельных сроков обработки персональных данных.
                        <br /><br />
                        Персональные данные предоставляются мною и обрабатываются Оператором для достижения следующих целей: предоставление Субъекту персональных данных возможности осуществления обратной связи с Оператором; регистрация Субъекта персональных данных в базе данных Оператора с последующим направлением почтовых сообщений, смс-уведомлений, звонков, в том числе рекламного, справочного, маркетингового содержания от Оператора; направление Субъекту персональных данных информационных и новостных рассылок, приглашений на мероприятия Оператора, партнеров Оператора и другой информации рекламно-новостного содержания; подтверждение личности Субъекта персональных данных; для предоставления Субъекту персональных данных консультаций по вопросам, касающимся оказываемых услуг; осуществление маркетинговой деятельности; оценка работы Сайта; оказание услуг; повышение качества оказания услуг, качества работы Сайта; обработка претензий и обращений Субъекта персональных данных.
                        <br /><br />
                        Я понимаю и соглашаюсь с тем, что Оператор не проверяет достоверность персональных данных, предоставляемых мной, не имеет возможности оценивать мою дееспособность, и исходит из того, что я предоставляю достоверные персональные данные и поддерживаю такие данные в актуальном состоянии.
                        <br /><br />
                        Настоящее согласие на обработку персональных данных действует с момента его предоставления Оператору и является бессрочным. Согласие может быть отозвано мной в любое время путем направления Оператору заявления в простой письменной форме по адресу: 460048 г. Оренбург, ул. Фронтовиков, д. 6/1, кв. 413 или на адрес электронной почты: olesiaturbabina@icloud.com.
                        <br /><br />
                        Персональные данные подлежат хранению в течение сроков, установленных законодательством Российской Федерации. Персональные данные уничтожаются по достижении целей обработки персональных данных, при прекращении деятельности Оператором, на основании письменного обращения Субъекта персональных данных с требованием о прекращении обработки его персональных данных (Оператор прекращает обработку таких персональных данных в течение 10 (десяти) рабочих дней, о чем будет направлено письменное уведомление субъекту персональных данных в течение 10 (десяти) рабочих дней).
                        <br /><br />
                        Оператор вправе привлекать для обработки персональных данных Субъекта персональных данных субподрядчиков, а также вправе передавать персональные данные для обработки своим аффилированным лицам, обеспечивая при этом принятие такими субподрядчиками и аффилированными лицами соответствующих обязательств в части сохранения конфиденциальности персональных данных.
                    </p>
                </div>
            </div>
        )
    }
}

export default Agreement;