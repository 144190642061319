import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import NavHeader from "./NavHeader";


const Layout = () => {
    return (
        <>
            <NavHeader />
            <Outlet />
            <Footer />
        </>
    );
};

export default Layout;